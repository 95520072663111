export const GearSourcesIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='20 17 62 69'>
    <linearGradient
      x1='16.447'
      x2='78.45'
      y1='76.797'
      y2='31.369'
      gradientUnits='userSpaceOnUse'
      id='GearSourcesIcon-0'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      d='M66.29 54.68c.53 0 .97-.43.97-.97v-4.73a.97.97 0 00-.97-.97h-1.81c-.33-1.32-.84-2.56-1.54-3.72l1.28-1.28a.97.97 0 000-1.36l-3.34-3.34c-.36-.36-1-.36-1.37 0l-1.28 1.28c-1.16-.69-2.4-1.21-3.72-1.54v-1.81a.97.97 0 00-.97-.97h-4.73a.97.97 0 00-.97.97v1.81c-1.32.33-2.56.84-3.72 1.54l-1.28-1.28a.97.97 0 00-1.36 0l-3.34 3.34a.97.97 0 000 1.36l1.28 1.28c-.69 1.16-1.21 2.4-1.54 3.72h-1.81a.97.97 0 00-.97.97v4.73c0 .53.43.97.97.97h1.81c.33 1.32.84 2.56 1.54 3.72l-1.28 1.28a.97.97 0 000 1.36l3.34 3.34c.36.36 1.01.36 1.37 0l1.28-1.28c1.16.69 2.4 1.21 3.72 1.54v1.81c0 .53.43.97.97.97h4.73c.53 0 .97-.43.97-.97v-1.81c1.32-.33 2.56-.84 3.72-1.54l1.28 1.28a.97.97 0 001.36 0l3.34-3.34a.97.97 0 000-1.36l-1.28-1.28c.69-1.16 1.21-2.4 1.54-3.72h1.81zm-15.11 3.94c-4.01 0-7.28-3.26-7.28-7.27s3.26-7.28 7.28-7.28c4.01 0 7.27 3.26 7.27 7.28s-3.26 7.27-7.27 7.27z'
      fill='url(#GearSourcesIcon-0)'></path>
    <linearGradient
      id='GearSourcesIcon-1'
      x1='16.447'
      x2='78.45'
      y1='76.797'
      y2='31.369'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-1)'
      d='M51.18 44.07c-4.01 0-7.28 3.26-7.28 7.28s3.26 7.27 7.28 7.27c4.01 0 7.27-3.26 7.27-7.27s-3.26-7.28-7.27-7.28zm0 12.62c-2.95 0-5.34-2.4-5.34-5.34 0-2.95 2.4-5.34 5.34-5.34 2.95 0 5.34 2.4 5.34 5.34s-2.39 5.34-5.34 5.34z'></path>
    <linearGradient
      id='GearSourcesIcon-2'
      x1='2.6'
      x2='64.603'
      y1='57.898'
      y2='12.47'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-2)'
      d='M35.71 40.44l-3.68-2.07a5.646 5.646 0 00-5.57-6.52c-3.11 0-5.64 2.53-5.64 5.64s2.53 5.64 5.64 5.64c1.49 0 2.89-.58 3.94-1.6l3.58 2.01c.26.15.57.23.87.23.64 0 1.23-.35 1.55-.9.47-.86.17-1.95-.69-2.43z'></path>
    <linearGradient
      id='GearSourcesIcon-3'
      x1='14.5'
      x2='76.502'
      y1='74.138'
      y2='28.711'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-3)'
      d='M33.97 58.94l-3.84 2.16a5.639 5.639 0 00-3.67-1.37c-3.11 0-5.64 2.53-5.64 5.64s2.53 5.64 5.64 5.64 5.64-2.53 5.64-5.64c0-.41-.05-.82-.14-1.23l3.75-2.11c.85-.48 1.16-1.57.68-2.42a1.797 1.797 0 00-2.42-.67z'></path>
    <linearGradient
      id='GearSourcesIcon-4'
      x1='4.058'
      x2='66.061'
      y1='59.888'
      y2='14.46'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-4)'
      d='M51.28 17.35c-3.11 0-5.64 2.53-5.64 5.64 0 2.45 1.59 4.6 3.87 5.36v4.03c0 .98.8 1.78 1.78 1.78s1.78-.8 1.78-1.78v-4.03a5.666 5.666 0 003.87-5.36c-.02-3.11-2.55-5.64-5.66-5.64z'></path>
    <linearGradient
      id='GearSourcesIcon-5'
      x1='18.387'
      x2='80.39'
      y1='79.445'
      y2='34.017'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-5)'
      d='M67.72 43.77c.3 0 .6-.08.87-.23l3.3-1.85c1.03.93 2.37 1.45 3.76 1.45 3.11 0 5.64-2.53 5.64-5.64s-2.53-5.64-5.64-5.64a5.646 5.646 0 00-5.53 6.75l-3.27 1.84c-.85.48-1.16 1.57-.68 2.42.31.55.91.9 1.55.9z'></path>
    <linearGradient
      id='GearSourcesIcon-6'
      x1='30.273'
      x2='92.276'
      y1='95.668'
      y2='50.24'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-6)'
      d='M75.65 59.73c-1.28 0-2.5.43-3.49 1.22l-3.57-2.01c-.86-.48-1.94-.17-2.42.68-.48.85-.18 1.94.68 2.42l3.35 1.88c-.13.48-.2.96-.2 1.46 0 3.11 2.53 5.64 5.64 5.64s5.64-2.53 5.64-5.64a5.633 5.633 0 00-5.63-5.65z'></path>
    <linearGradient
      id='GearSourcesIcon-7'
      x1='28.803'
      x2='90.806'
      y1='93.661'
      y2='48.233'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#GearSourcesIcon-7)'
      d='M53.06 74.14V70.1c0-.98-.8-1.78-1.78-1.78s-1.78.8-1.78 1.78v4.03a5.666 5.666 0 00-3.87 5.36c0 3.11 2.53 5.64 5.64 5.64s5.64-2.53 5.64-5.64c.01-2.44-1.58-4.6-3.85-5.35z'></path>
  </svg>
)
