export const PlugIcon = () => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='20 20 60 60'
    xmlSpace='preserve'>
    <linearGradient
      id='PlugIcon-1'
      gradientUnits='userSpaceOnUse'
      x1='22.6092'
      y1='83.394'
      x2='72.9481'
      y2='25.0746'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#PlugIcon-1)'
      d='M71.88,24.09c-0.78-0.47-1.69-0.66-2.59-0.55c-0.9,0.11-1.74,0.52-2.39,1.16l-0.7,0.7h0
			c-3-1.27-6.31-1.61-9.5-0.98c-3.2,0.62-6.13,2.19-8.43,4.49l-3.14,3.14v0c-0.27,0.27-0.43,0.65-0.43,1.03
			c0,0.39,0.15,0.76,0.43,1.03l2.45,2.45l-6.3,6.3h0c-0.56,0.56-0.78,1.39-0.58,2.16c0.21,0.77,0.81,1.37,1.58,1.58
			c0.77,0.21,1.59-0.01,2.16-0.58l6.3-6.3l9.87,9.87l-6.3,6.3c-0.56,0.56-0.78,1.39-0.58,2.16c0.21,0.77,0.81,1.37,1.58,1.58
			c0.77,0.21,1.59-0.01,2.16-0.58l6.3-6.3l2.45,2.45c0.27,0.27,0.65,0.43,1.03,0.43c0.39,0,0.76-0.15,1.03-0.43l3.14-3.14h0
			c2.3-2.3,3.86-5.24,4.49-8.43c0.62-3.19,0.28-6.5-0.98-9.5l0.7-0.7c0.64-0.64,1.05-1.48,1.16-2.39c0.11-0.9-0.09-1.82-0.55-2.59
			l4.12-4.12l-4.35-4.35L71.88,24.09z'
    />

    <linearGradient
      id='PlugIcon-2'
      gradientUnits='userSpaceOnUse'
      x1='21.2822'
      y1='82.2485'
      x2='71.621'
      y2='23.9291'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#PlugIcon-2)'
      d='M24.01,80.66l4.12-4.12
			c0.78,0.47,1.69,0.66,2.59,0.55c0.9-0.11,1.74-0.52,2.39-1.16l0.7-0.7h0c3,1.27,6.31,1.61,9.5,0.98c3.2-0.62,6.13-2.19,8.43-4.49
			l3.14-3.14v0c0.27-0.27,0.43-0.65,0.43-1.03c0-0.39-0.15-0.76-0.43-1.03L33.79,45.44c-0.27-0.27-0.65-0.43-1.03-0.43
			c-0.39,0-0.76,0.15-1.03,0.43l-3.14,3.14h0c-2.3,2.3-3.86,5.24-4.49,8.43c-0.62,3.19-0.28,6.5,0.98,9.5l-0.7,0.7v0
			c-0.64,0.64-1.05,1.48-1.16,2.39c-0.11,0.9,0.09,1.82,0.55,2.59l-4.12,4.12L24.01,80.66z'
    />
  </svg>
)
