export const LetterWriteIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0'
    y='0'
    viewBox='22 30 58 50'>
    <linearGradient
      id='LetterWriteIcon-1'
      x1='29.728'
      x2='91.731'
      y1='93.471'
      y2='51.727'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-1)'
      d='M55.72 71.83c-.39.39-.6.91-.6 1.46v4.12c0 .55.22 1.07.6 1.46.39.39.91.6 1.46.6h4.12c.55 0 1.07-.21 1.46-.6l10.89-10.92-7.02-7.02-10.91 10.9z'></path>
    <linearGradient
      id='LetterWriteIcon-2'
      x1='28.283'
      x2='90.286'
      y1='91.326'
      y2='49.581'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-2)'
      d='M74.87 54.76c-1.32-.01-2.59.52-3.52 1.46l-1.81 1.81 7.02 7.02 1.81-1.81c.94-.93 1.47-2.2 1.46-3.52 0-1.32-.52-2.58-1.45-3.51-.93-.93-2.2-1.45-3.51-1.45z'></path>
    <linearGradient
      id='LetterWriteIcon-3'
      x1='8.533'
      x2='70.536'
      y1='61.991'
      y2='20.246'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-3)'
      d='M79.4 32.37c-.34-.69-.86-1.28-1.51-1.69-.65-.41-1.41-.63-2.18-.64H26.29c-.77 0-1.52.22-2.18.64-.65.41-1.17 1-1.51 1.69L51 54.22l28.4-21.85z'></path>
    <linearGradient
      id='LetterWriteIcon-4'
      x1='8.504'
      x2='70.507'
      y1='61.948'
      y2='20.203'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-4)'
      d='M22.17 37.23v29.88c0 .35.05.71.14 1.05l17.42-17.42-17.56-13.51z'></path>
    <linearGradient
      id='LetterWriteIcon-5'
      x1='20.716'
      x2='82.719'
      y1='80.087'
      y2='38.342'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-5)'
      d='M63.71 58.03l-4.74-4.76-6.71 5.17c-.36.28-.8.43-1.26.43s-.9-.15-1.26-.43l-6.71-5.17-17.79 17.82c.34.09.7.14 1.05.14h25.08c.3-.87.8-1.65 1.44-2.31l10.9-10.89z'></path>
    <linearGradient
      id='LetterWriteIcon-6'
      x1='22.756'
      x2='84.759'
      y1='83.116'
      y2='41.372'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.486' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#LetterWriteIcon-6)'
      d='M62.26 50.74l4.37 4.37 1.79-1.81a9.153 9.153 0 016.45-2.66c1.77 0 3.49.52 4.96 1.5V37.23L62.26 50.74z'></path>
  </svg>
)
