export const BinaryTreeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='12 17 80 64'>
    <linearGradient
      id='BinaryTreeIcon-1'
      x1='-11.951'
      x2='73.38'
      y1='59.886'
      y2='10.775'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-1)'
      fillRule='evenodd'
      d='M52 28.52c1.44 0 2.83-.57 3.85-1.59a5.45 5.45 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85A5.45 5.45 0 0052 17.64c-1.44 0-2.83.57-3.85 1.59a5.45 5.45 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85A5.45 5.45 0 0052 28.52z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-2'
      x1='-5.231'
      x2='80.1'
      y1='71.562'
      y2='22.451'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-2)'
      fillRule='evenodd'
      d='M30.25 41.92c.29-.29.45-.69.45-1.09v-2.88h42.59v2.87c0 .41.16.81.45 1.09.29.29.68.46 1.09.46.41 0 .81-.17 1.09-.45.29-.29.45-.69.45-1.09v-4.42l-.01-.17c-.09-.78-.74-1.37-1.53-1.37H53.52v-3.28c0-.84-.68-1.54-1.52-1.55h-.01a1.536 1.536 0 00-1.54 1.54v3.29H29.17h-.01a1.536 1.536 0 00-1.54 1.54v4.41c0 .41.16.81.45 1.09.58.59 1.61.59 2.18.01z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-3'
      x1='-6.289'
      x2='79.041'
      y1='69.722'
      y2='20.611'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-3)'
      fillRule='evenodd'
      d='M29.17 54.76c1.44 0 2.83-.57 3.85-1.59s1.59-2.41 1.59-3.85-.57-2.83-1.59-3.85a5.45 5.45 0 00-3.85-1.59c-1.44 0-2.83.57-3.85 1.59a5.45 5.45 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85a5.433 5.433 0 003.85 1.59z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-4'
      x1='5.074'
      x2='90.405'
      y1='89.467'
      y2='40.356'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-4)'
      fillRule='evenodd'
      d='M74.83 43.87c-1.44 0-2.83.57-3.85 1.59a5.45 5.45 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85a5.45 5.45 0 003.85 1.59c1.44 0 2.83-.57 3.85-1.59a5.433 5.433 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85a5.475 5.475 0 00-3.85-1.59z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-5'
      x1='0.245'
      x2='85.576'
      y1='81.076'
      y2='31.965'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-5)'
      fillRule='evenodd'
      d='M18.84 67.73c.29-.29.45-.69.45-1.09v-2.88h19.76v2.88c0 .84.68 1.54 1.53 1.55a1.536 1.536 0 001.54-1.54v-4.42l-.01-.18c-.09-.78-.75-1.36-1.53-1.36H30.7v-2.87c0-.41-.16-.81-.45-1.09-.29-.29-.68-.45-1.09-.45h-.01a1.536 1.536 0 00-1.54 1.54v2.88h-9.87a1.536 1.536 0 00-1.54 1.54v4.41c0 .41.16.81.45 1.1.59.56 1.61.55 2.19-.02z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-6'
      x1='11.608'
      x2='96.939'
      y1='100.82'
      y2='51.709'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-6)'
      fillRule='evenodd'
      d='M76.38 57.81a1.536 1.536 0 00-1.54-1.54h-.01c-.41 0-.8.16-1.08.45-.29.29-.46.69-.45 1.09v2.88h-9.87-.01c-.84 0-1.51.65-1.54 1.54v4.41c0 .41.16.8.45 1.1.29.29.68.45 1.1.45.84-.01 1.52-.7 1.52-1.54v-2.88h19.76v2.88a1.536 1.536 0 001.54 1.54 1.536 1.536 0 001.54-1.54v-4.42l-.01-.18c-.09-.78-.75-1.36-1.53-1.36h-9.87v-2.88z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-7'
      x1='1.94'
      x2='87.27'
      y1='84.021'
      y2='34.91'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-7)'
      fillRule='evenodd'
      d='M17.75 69.48c-1.44 0-2.83.57-3.85 1.59a5.433 5.433 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85a5.45 5.45 0 003.85 1.59c1.44 0 2.83-.57 3.85-1.59a5.45 5.45 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85a5.45 5.45 0 00-3.85-1.59z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-8'
      x1='7.622'
      x2='92.952'
      y1='93.893'
      y2='44.782'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-8)'
      fillRule='evenodd'
      d='M40.58 69.48c-1.44 0-2.83.57-3.85 1.59a5.433 5.433 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85a5.45 5.45 0 003.85 1.59c1.44 0 2.83-.57 3.85-1.59a5.45 5.45 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85a5.433 5.433 0 00-3.85-1.59z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-9'
      x1='13.303'
      x2='98.633'
      y1='103.764'
      y2='54.653'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-9)'
      fillRule='evenodd'
      d='M63.42 69.48c-1.44 0-2.83.57-3.85 1.59a5.433 5.433 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85s2.41 1.59 3.85 1.59 2.83-.57 3.85-1.59a5.45 5.45 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85a5.467 5.467 0 00-3.85-1.59z'
      clipRule='evenodd'></path>
    <linearGradient
      id='BinaryTreeIcon-10'
      x1='18.985'
      x2='104.315'
      y1='113.636'
      y2='64.525'
      gradientUnits='userSpaceOnUse'>
      <stop offset='0' stopColor='#00AECE'></stop>
      <stop offset='0.486' stopColor='#8A2FF1'></stop>
      <stop offset='1' stopColor='#FD67FF'></stop>
    </linearGradient>
    <path
      fill='url(#BinaryTreeIcon-10)'
      fillRule='evenodd'
      d='M90.1 71.07a5.45 5.45 0 00-3.85-1.59c-1.44 0-2.83.57-3.85 1.59a5.433 5.433 0 00-1.59 3.85c0 1.44.57 2.83 1.59 3.85a5.45 5.45 0 003.85 1.59c1.44 0 2.83-.57 3.85-1.59a5.45 5.45 0 001.59-3.85c0-1.44-.57-2.83-1.59-3.85z'
      clipRule='evenodd'></path>
  </svg>
)
